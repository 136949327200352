<template>
  <!-- 當沒有貼文時 -->
  <div v-if="!listData.length" class="card card-block card-stretch">
    <div class="card-body text-center">
      {{ $t('label.thereAreCurrentlyNoGoods') }}
    </div>
  </div>
  <!-- 每一則貼文 -->
  <div class="d-flex flex-wrap mx-n2">
    <template v-for="item in listData" :id="item.goods_id" :key="item.goods_id">
      <div class="w-50 p-2">
        <div class="rounded overflow-hidden shadow-sm">

          <!-- <img class="card-img-top" :src="item.goods_img"> -->
          <div class="prod-img" data-bs-toggle="modal" :style="`background-image: url(${item.goods_img});`">

          </div>

          <div class="p-3 bg-white">
            <div class="d-flex justify-content-between align-items-start">
              <h3 class="mt-0 goods_name">{{ item.goods_name }}</h3>

              <div class="dropdown">
                <span class="dropdown-toggle" id="postdata-5" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                  <i class="ri-more-fill"></i>
                </span>
                <div class="dropdown-menu m-0 p-0" aria-labelledby="postdata-5">
                  <a class="dropdown-item p-2" href="javascript:void(0)" @click="checkEdit(item)" data-bs-toggle="modal" data-bs-target="#modals-edit-product">
                    <div class="d-flex align-items-center">
                      <i class="ri-ball-pen-line h4"></i>
                      <div class="data ms-2">
                        <p class="mb-0">{{ $t('button.edit') + $t('label.products') }}</p>
                      </div>
                    </div>
                  </a>
                  <a class="dropdown-item p-2" href="javascript:void(0)" @click="delGoods(item.goods_id)">
                    <div class="d-flex align-items-center">
                      <i class="ri-close-circle-line h4"></i>
                      <div class="data ms-2">
                        <p class="mb-0">{{ $t('button.del') + $t('label.products') }}</p>
                      </div>
                    </div>
                  </a>
                  <a v-if="item.is_enabled" class="dropdown-item p-2" href="javascript:void(0)" @click="toggleEnable(item)">
                    <div class="d-flex align-items-center">
                      <i class="ri-eye-off-line h4"></i>
                      <div class="data ms-2">
                        <p class="mb-0">{{ $t('label.deactivate') }}</p>
                      </div>
                    </div>
                  </a>
                  <a v-else class="dropdown-item p-2" href="javascript:void(0)" @click="toggleEnable(item)">
                    <div class="d-flex align-items-center">
                      <i class="ri-eye-line h4"></i>
                      <div class="data ms-2">
                        <p class="mb-0">{{ $t('label.enable') }}</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <!-- <p>{{ item.goods_desc }}</p> -->
            <span class="text-danger fw-bold fs-2 me-2">{{ item.goods_price }}</span><small><del>{{
                            item.goods_original_price }}</del></small>
            <div class="d-flex justify-content-between">
              <span>商品數量：{{ item.number }}</span>
              <el-tag v-if="item.is_enabled === 1" class="mx-1" effect="plain" round>
                {{ item.is_enabled_name }}
              </el-tag>
              <el-tag v-if="item.is_enabled === 0" class="ml-2" type="warning" round>
                {{ item.is_enabled_name }}
              </el-tag>
            </div>
          </div>
        </div>
      </div>
    </template>

    <modal ref="modals" id="modals-edit-product" dialogClass="modal-fullscreen-sm-down" tabindex="-1" :title="$t('button.edit') + $t('label.products')" aria-labelledby="modalsLabel" aria-hidden="true">
      <model-header>
        <h5 class="modal-title" id="modalsLabel">{{ $t('button.edit') }}{{ $t('label.products') }}</h5>
        <button :dismissable="true" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">
          <i class="ri-close-fill"></i>
        </button>
      </model-header>
      <model-body>
        <div class="d-flex align-items-center">
          <form class="post-text w-100 relative" action="javascript:void();">
            <el-input v-model="editForm.goods_name" type="text" class="mb-2" :placeholder="$t('label.products') + $t('label.name')" @input="checkValidation" />
            <vue-editor v-model="editForm.goods_desc" :placeholder="$t('label.products') + $t('label.desc')"></vue-editor>
            <el-input v-model="editForm.goods_original_price" type="number" @input="checkNumber('goods_original_price', $event), checkValidation()" class="mb-2" :placeholder="$t('label.originalPrice')" min="1" />
            <el-input v-model="editForm.goods_price" type="number" @input="checkNumber('goods_price', $event), checkValidation()" class="mb-2" :placeholder="$t('label.salePrice')" min="1" />
            <el-input v-model="editForm.number" type="number" @input="checkNumber('number', $event), checkValidation()" class="mb-2" :placeholder="$t('label.products') + $t('label.quantity')" min="1" />
          </form>
        </div>
        <hr />
        <!-- <hr /> -->
        <div class="d-flex justify-content-between">
          <ul class="post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
            <!-- <hr v-if="editForm.goods_img.length || uploadLoading" /> -->

            <div v-if="editForm?.goods_img?.length === 0 && !uploadLoading">
              <el-upload
                :show-file-list="false"
                :headers="{ 'authorization': token }"
                :accept="commom.uploadOption.accept"
                :data="{ 'type': 'goods' }"
                action="#"
                :on-success="handleAvatarSuccess"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-progress="handleProgress"
                :before-upload="handleBeforeUpload"
                :on-change="getBase64"
                :auto-upload="false"
              >
                <el-tooltip class="item" effect="dark" :content="$t('label.uploadImage')" placement="bottom">
                  <button type="button" class="btn border rounded d-flex justify-content-center align-items-center" style="width: 148px; height: 148px;">
                    <i class="far ri-image-line fa-fw me-0"></i>
                  </button>
                </el-tooltip>
              </el-upload>
            </div>
            <div v-if="editForm.goods_img.length || uploadLoading">
              <div>
                <div>
                  <ul class="el-upload-list el-upload-list--picture-card">
                    <!-- upload Percent -->
                    <img :src="editForm.goods_img" alt="">
                    <li v-for="(m, index) in editForm.goods_img" :key="index" class="el-upload-list__item is-success" tabindex="0">
                      <img class="el-upload-list__item-thumbnail" :src="m.media_url" alt="">
                      <label class="el-upload-list__item-status-label">
                        <i class="el-icon el-icon--upload-success el-icon--check">
                          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z">
                            </path>
                          </svg>
                        </i>
                      </label>
                      <i class="el-icon el-icon--close">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor"
                            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                          </path>
                        </svg>
                      </i>
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview">
                          <i @click="onPreview()" class="el-icon el-icon--zoom-in">
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                              <path fill="currentColor"
                                d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                              </path>
                            </svg>
                          </i>
                        </span>
                        <span @click="onDelete(index)" class="el-upload-list__item-delete">
                          <i class="el-icon el-icon--delete">
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                              <path fill="currentColor"
                                d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z">
                              </path>
                            </svg>
                          </i>
                        </span>
                      </span>
                    </li>
                    <li v-if="uploadLoading" v-loading="uploadLoading" class="el-upload-list__item is-success" tabindex="0">
                      <div class="text-primary text-center" style="font-size: 16px; z-index: 99999;">
                        <div class="p-1">{{ uploadPercent }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ul>

        </div>
        <!-- :disabled="formIsPass === false" -->
        <!-- <button :disabled="formIsPass === false" class="btn btn-primary d-block w-100 mt-3" @click="onEditPost(editForm)">
          {{ $t('button.save') + $t('button.edit') }}
        </button> -->
        <button class="btn btn-primary d-block w-100 mt-3" @click="onEditPost(editForm)">
          {{ $t('button.save') + $t('button.edit') }}
        </button>
      </model-body>
    </modal>

    <div v-if="uploadGoodsShow" class="modal_wrap">
      <div class="modal_inner">
        <div style="width: 100%;height: 80vh">
          <VueCropper
            ref="cropper"
            :img="uploadGoodsImg"
            :outputSize="option.outputSize"
            :outputType="option.outputType"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            :canScale="option.canScale"
            :centerBox="option.centerBox"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
          />
        </div>
        <div style="display: flex; justify-content: flex-end;" class="mt-2">
          <button @click="uploadGoodsShow = false" type="button" class="btn btn-primary me-2">{{ $t('label.cancel') }}</button>
          <button @click="confirmCropper" type="button" class="btn btn-primary me-2">
            <i v-show="commom.btnLoader" class="fa fa-spinner fa-spin"></i>
            {{ $t('button.confirm') }}
          </button>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogVisible" width="80%">
      <div class="text-center">
        <img :src="dialogUrl" fit="fill" />
          <!-- <template #placeholder>
            <div class="image-slot">Loading<span class="dot">...</span></div>
          </template> -->
        <!-- </el-image> -->
      </div>
    </el-dialog>
  </div>
</template>
  
<script setup>
import { getGoods, editGoods, deleteGoods } from '@/api/goods'
import { useStore } from 'vuex'
import { reactive, ref, getCurrentInstance } from 'vue'
import { commomData } from '@/utils/commonVmodel'
import { VueEditor } from 'vue3-editor'
console.log(VueEditor)
import { VueCropper } from "vue-cropper";
import 'vue-cropper/dist/index.css';
import { upload } from '@/api/user'
const { proxy } = getCurrentInstance()
const $toast = proxy.$toast
const store = useStore()
const token = store.getters['User/tokenState']
const user = store.getters['User/userState']

// const formIsPass = ref(true)
const dialogVisible = ref(false)
// const dialogType = ref(null)
const dialogUrl = ref(null)
let commom = ref(commomData())

const uploadLoading = ref(false)
const uploadPercent = ref('0%')

const option =  ref({
  outputSize: 1,
  outputType: "png", // 產生圖片的格式
  autoCrop: true, // 是否要有截圖框
  autoCropWidth: 500, // 截圖框寬
  autoCropHeight: 500, //截圖框高
  fixedBox: false,
  canScale: true,
  centerBox: true,
  fixed: true,
  fixedNumber: [1, 1]
})

const cropper = ref(null)

const handleRemove = (uploadFile, uploadFiles) => {
  console.log(uploadFile, uploadFiles)
  editForm.value.value.goods_img = editForm.value.value.goods_img.filter(
    element => {
      const c = !element.message_template_media_url
        ? element.message_template_media_img
        : element.message_template_media_url
      return c !== uploadFile.url
    }
  )
}

const handlePictureCardPreview = uploadFile => {
  dialogUrl.value = !uploadFile.url
  dialogVisible.value = true
}
const handleAvatarSuccess = response => {
  uploadLoading.value = false
  const d = {
    media_id: '',
    media_type: response.file.file_type === 'video' ? 'video' : 'photo',
    media_url: response.file.file_url,
    media_img: response.file.file_path,
    media_video: response.file.file_video_url
      ? response.file.file_video_url
      : '',
    img: response.file.file_path.img,
    square: response.file.file_path.square,
    thumb: response.file.file_path.thumb
  }
  editForm.value.goods_img.push(d)
}

const handleProgress = event => {
  if (event.percent > 1 && event.percent < 100) {
    uploadLoading.value = true
    uploadPercent.value = `${Math.round(event.percent)}%`
  }
}

const uploadGoodsImg = ref(null);
const uploadGoodsShow = ref(false);

const checkValidation = () => {
  // getBase64(val.raw)
  // let formItems = Object.keys(editForm.value)
  // console.log('checkValidation', formItems)
  // for (let i = 0; i < formItems.length; i++) {
  //   console.log(editForm.value[formItems[i]])
  //   if (
  //     editForm.value[formItems[i]] == ''
  //     // || editForm.value[formItems[i]].length === 0
  //   ) {
  //     console.log(formItems[i])
  //     console.log(editForm.value[formItems[i]])

  //     return (formIsPass.value = false)
  //   }
  // }
  // return (formIsPass.value = true)
}

const getBase64 = (file) => {
  const reader = new FileReader();

  reader.readAsDataURL(file.raw)
  reader.onload = function () {
    uploadGoodsImg.value = reader.result
    uploadGoodsShow.value = true
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

const listParams = reactive({
  page: 1,
  page_size: 9999,
  sort_by: 'desc',
  order_by: 'created_at',
  account: user.account
})

const editForm = ref({
  goods_name: '',
  goods_desc: '',
  goods_img: [],
  goods_original_price: '',
  goods_price: '',
  number: '',
  // publish_date: [],
  // publish_start_date: '',
  // publish_end_date: '',
  goods_id: ''
})

const listData = ref({})

getGoods(listParams).then(res => {
  listData.value = res.data
})

const delGoods = delId => {
  deleteGoods({ id: delId }).then(res => {
    $toast.show(res.message)
    setTimeout(() => {
      location.reload()
    }, 2000)
  })
}

const toggleEnable = item => {
  // let formData = { ...item }
  // delete formData.publish_date;
  // formData.goods_img = item.goods_img[0].media_img
  let trigger = item.is_enabled === 0 ? 1 : 0

  editGoods(item.goods_id, { is_enabled: trigger })
    .then(res => {
      $toast.show(res.message)
      setTimeout(() => {
        location.reload()
      }, 2000)
    })
    .catch(err => {
      $toast.show(err.response)
    })
}

const onEditPost = value => {
  console.log('1', value)
  if (!value.goods_name) {
    $toast.show('請輸入商品名稱')
    return
  }
  if (!value.goods_desc) {
    $toast.show('請輸入商品描述')
    return
  }
  if (!value.goods_original_price) {
    $toast.show('請輸入原價')
    return
  }
  if (!value.goods_price) {
    $toast.show('請輸入促銷價格')
    return
  }
  if (!value.number) {
    $toast.show('請輸入商品數量')
    return
  }
  if (value.goods_img.length === 0) {
    $toast.show('請上傳商品圖片')
    return
  }
  const formData = { ...value }
  // delete formData.publish_date
  console.log('1', formData)
  formData.goods_img = value.goods_img[0].media_img
  console.log('2', formData)

  // debugger
  editGoods(editForm.value.goods_id, formData)
    .then(res => {
      // modals.value.hide()
      // Object.assign(formData, {
      //   goods_name: '',
      //   goods_desc: '',
      //   goods_img: [],
      //   goods_original_price: '',
      //   goods_price: '',
      //   number: ''
      // })
      // store.dispatch('User/isPlanAction', false)
      console.log(res.message)
      $toast.show(res.message)
      // location.reload()
      setTimeout(() => {
        location.reload()
      }, 2000)
    })
    .catch(err => {
      $toast.show(err.response)
    })
}

const checkNumber = (inputModel, e) => {
  if (parseInt(e) < 0) {
    editForm.value[inputModel] = 0
  }
}

const onPreview = () => {
  dialogUrl.value = editForm.value.goods_img[0].media_url
  // dialogType.value = editForm.value.goods_img[0].media_type

  dialogVisible.value = !dialogVisible.value
}

const onDelete = index => {
  editForm.value.goods_img.splice(index, 1)
  checkValidation()
}

const checkEdit = product => {
  editForm.value = { ...product }
  editForm.value.goods_original_price = editForm.value.goods_original_price
    .replace('$', '')
    .replaceAll(',', '')
  editForm.value.goods_price = editForm.value.goods_price
    .replace('$', '')
    .replaceAll(',', '')
  editForm.value.goods_img = [{ media_url: product.goods_img }]
  // editForm.value.publish_date.push(editForm.value.publish_start_date)
  // editForm.value.publish_date.push(editForm.value.publish_end_date)
}

const confirmCropper = () => {
  commom.value.btnLoader = true
  cropper.value.getCropData((data) => {
    const file = dataURLtoFile(data, 'a.png');
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", 'goods');
    upload(formData)
      .then((response) => {
        // $toast.show(res.message)
        commom.value.btnLoader = false
        uploadGoodsImg.value = ''
        uploadGoodsShow.value = false

        uploadLoading.value = false
        const d = {
          media_id: '',
          media_type: response.file.file_type === 'video' ? 'video' : 'photo',
          media_url: response.file.file_url,
          media_img: response.file.file_path,
          media_video: response.file.file_video_url
            ? response.file.file_video_url
            : '',
          img: response.file.file_path.img,
          square: response.file.file_path.square,
          thumb: response.file.file_path.thumb
        }
        editForm.value.goods_img.push(d)
      })
      .catch(err => {
        commom.value.btnLoader = false
        $toast.show(err.response.data.message)
      })
  });
}

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}
</script>
<style scoped>
.ri-more-fill {
  font-size: 20px;
}

.dropdown-toggle::after {
  display: none;
}

.goods_name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
    min-height: 80px;
    max-height: 80px;
}

.modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 2000;
}
.modal_inner {
  width: 90%;
  margin: 20px auto;
  background: #FFF;
  border-radius: 10px;
  padding: 10px;
}
.modal_inner img {
  max-width: 100%;
  max-height: 90vh;
}

@media screen and (max-width: 420px) {
  .prod-img {
    height: 196px;
  }
}

@media screen and (max-width: 767px) {
  .goods_name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    min-height: 108px;
    max-height: 108px;
  }
}
</style>