<template>
  <div>
    <iq-card id="post-modal-data" body-class="iq-card iq-card-block iq-card-stretch iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('label.add') }}{{ $t('label.products') }}</h4>
      </template>
      <template v-slot:body>
        <div class="d-flex align-items-center">
          <!-- 新增商品 -->
          <form class="post-text ml-3 w-100">
            <input type="text" :placeholder="$t('label.release') + $t('label.products')" class="rounded form-control" style="border: none" data-bs-toggle="modal" data-bs-target="#modals-product" />
          </form>
        </div>
        <hr />
        <div class="d-flex align-items-center ">
          <div class="bg-soft-danger text-center rounded">
            <div class="text-danger px-2"> 我的推廣專頁
              <router-link :to="`/auth/home/?user_account=${user.account}`" target="_blank">
                連結
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </iq-card>
    <modal ref="modals" id="modals-product" dialogClass="modal-fullscreen-sm-down" tabindex="-1" :title="$t('label.add') + $t('label.products')" aria-labelledby="modalsLabel" aria-hidden="true">
      <model-header>
        <h5 class="modal-title" id="modalsLabel">{{ $t('label.add') }}{{ $t('label.products') }}</h5>
        <button :dismissable="true" class="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close">
          <i class="ri-close-fill"></i>
        </button>
      </model-header>
      <model-body>
        <div class="d-flex align-items-center">
          <form class="post-text w-100 relative" action="javascript:void();">
            <el-input v-model="postForm.goods_name" type="text" class="mb-2" :placeholder="$t('label.products') + $t('label.name')" @input="checkValidation" />
            <vue-editor v-model="postForm.goods_desc" :placeholder="$t('label.products') + $t('label.desc')"></vue-editor>
            <!-- <el-input v-model="postForm.goods_desc" type="textarea" class="mb-2" :placeholder="$t('label.products') + $t('label.desc')" rows="5" @input="checkValidation" /> -->
            <el-input v-model="postForm.goods_original_price" type="number" @input="checkNumber('goods_original_price', $event), checkValidation()" class="mb-2" :placeholder="$t('label.originalPrice')" min="1" />
            <el-input v-model="postForm.goods_price" type="number" @input="checkNumber('goods_price', $event), checkValidation()" class="mb-2" :placeholder="$t('label.salePrice')" min="1" />
            <el-input v-model="postForm.number" type="number" @input="checkNumber('number', $event), checkValidation()" class="mb-2" :placeholder="$t('label.products') + $t('label.quantity')" min="1" />

            <!-- <div class="d-flex justify-content-between">
                <el-date-picker v-model="postForm.publish_date" type="daterange" range-separator="~"
                  :start-placeholder="$t('label.startDate')" :end-placeholder="$t('label.endDate')" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" @change="checkValidation" />
              </div> -->
            <div class="d-flex align-items-center">
              <el-text class="me-3">商品狀態</el-text>
              <el-radio-group v-model="postForm.is_enabled" class="ml-4">
                <el-radio label="0" size="large">停用</el-radio>
                <el-radio label="1" size="large">啟用</el-radio>
              </el-radio-group>
            </div>
          </form>
        </div>
        <hr />
        <p class="text-danger">建議上傳尺寸 500 * 500</p>
        <p class="text-danger">建議使用電腦上傳商品圖，如使用手機上傳會導致圖片過小</p>
        <div class="d-flex justify-content-between">
          <ul class="post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
            <div v-if="postForm.goods_img.length === 0 && !uploadLoading">
              <el-upload
                :show-file-list="false"
                :headers="{ 'authorization': token }"
                :accept="commom.uploadOption.accept"
                :data="{ 'type': 'goods' }"
                action="#"
                :on-success="handleAvatarSuccess"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-progress="handleProgress"
                :before-upload="handleBeforeUpload"
                :on-change="getBase64"
                :auto-upload="false"
              >
                <el-tooltip class="item" effect="dark" :content="$t('label.uploadImage')" placement="bottom">
                  <button type="button" class="btn border rounded d-flex justify-content-center align-items-center" style="width: 120px; height: 120px;">
                    <i class="far ri-image-line fa-fw me-0"></i>
                  </button>
                </el-tooltip>
              </el-upload>
            </div>
            <div v-if="postForm.goods_img.length || uploadLoading">
              <div>
                <div>
                  <ul class="el-upload-list el-upload-list--picture-card">
                    <!-- upload Percent -->
                    <li v-for="(m, index) in postForm.goods_img" :key="index" class="el-upload-list__item is-success" tabindex="0">
                      <img class="el-upload-list__item-thumbnail" :src="m.media_url" alt="">
                      <label class="el-upload-list__item-status-label">
                        <i class="el-icon el-icon--upload-success el-icon--check">
                          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z">
                            </path>
                          </svg>
                        </i>
                      </label>
                      <i class="el-icon el-icon--close">
                        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                          <path fill="currentColor"
                            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                          </path>
                        </svg>
                      </i>
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview">
                          <i @click="onPreview()" class="el-icon el-icon--zoom-in">
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                              <path fill="currentColor"
                                d="m795.904 750.72 124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704 352 352 0 0 0 0 704zm-32-384v-96a32 32 0 0 1 64 0v96h96a32 32 0 0 1 0 64h-96v96a32 32 0 0 1-64 0v-96h-96a32 32 0 0 1 0-64h96z">
                              </path>
                            </svg>
                          </i>
                        </span>
                        <span @click="onDelete(index)" class="el-upload-list__item-delete">
                          <i class="el-icon el-icon--delete">
                            <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                              <path fill="currentColor"
                                d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z">
                              </path>
                            </svg>
                          </i>
                        </span>
                      </span>
                    </li>
                    <li v-if="uploadLoading" v-loading="uploadLoading" class="el-upload-list__item is-success" tabindex="0">
                      <div class="text-primary text-center" style="font-size: 16px; z-index: 99999;">
                        <div class="p-1">{{ uploadPercent }}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ul>

        </div>
        <button :disabled="!isBtnEnabled" class="btn btn-primary d-block w-100 mt-3" @click="onAddPost(postForm)">
          {{ $t('label.release') + $t('label.products') }}
        </button>
      </model-body>
    </modal>

    <div v-if="uploadGoodsShow" class="modal_wrap">
      <div class="modal_inner">
        <div style="width: 100%;height: 80vh">
          <VueCropper
            ref="cropper"
            :img="uploadGoodsImg"
            :outputSize="option.outputSize"
            :outputType="option.outputType"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            :canScale="option.canScale"
            :centerBox="option.centerBox"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
          />
        </div>
        <div style="display: flex; justify-content: flex-end;" class="mt-2">
          <button @click="uploadGoodsShow = false" type="button" class="btn btn-primary me-2">{{ $t('label.cancel') }}</button>
          <button @click="confirmCropper" type="button" class="btn btn-primary me-2">
            <i v-show="commom.btnLoader" class="fa fa-spinner fa-spin"></i>
            {{ $t('button.confirm') }}
          </button>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogVisible" width="80%">
      <div class="text-center">
        <img :src="dialogUrl" fit="fill" />
          <!-- <template #placeholder>
            <div class="image-slot">Loading<span class="dot">...</span></div>
          </template> -->
      </div>
    </el-dialog>

  </div>
</template>
  <script setup>
import { addGoods } from '@/api/goods'
import { ref, reactive, getCurrentInstance, computed, onMounted } from 'vue'
import { VueEditor } from 'vue3-editor'
console.log(VueEditor)
import { useStore } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
import { VueCropper } from "vue-cropper";
import 'vue-cropper/dist/index.css';
import { upload } from '@/api/user'
const { proxy } = getCurrentInstance()
const $toast = proxy.$toast
const store = useStore()
const token = store.getters['User/tokenState']
const user = store.getters['User/userState']

const postForm = reactive({
  goods_name: '',

  goods_desc: '',

  goods_img: [],
  goods_original_price: '',
  goods_price: '',
  number: '',

  is_enabled: '1'
})

// const formIsPass = ref(false)
const dialogVisible = ref(false)
const dialogType = ref(null)
const dialogUrl = ref(null)

let commom = ref(commomData())

const uploadLoading = ref(false)
const uploadPercent = ref('0%')

let option = ref({
  outputSize: 1,
  outputType: "png", // 產生圖片的格式
  autoCrop: true, // 是否要有截圖框
  autoCropWidth: 500, // 截圖框寬
  autoCropHeight: 500, //截圖框高
  fixedBox: false,
  canScale: true,
  centerBox: true,
  fixed: true,
  fixedNumber: [1, 1]
})

const cropper = ref(null)

const isMobileDevice = computed(() => {
  const userAgent = window.navigator.userAgent
  return /Mobile/i.test(userAgent)
})

const handleRemove = (uploadFile, uploadFiles) => {
  console.log(uploadFile, uploadFiles)
  postForm.value.goods_img = postForm.value.goods_img.filter(element => {
    const c = !element.message_template_media_url
      ? element.message_template_media_img
      : element.message_template_media_url
    return c !== uploadFile.url
  })
}

const handlePictureCardPreview = uploadFile => {
  dialogUrl.value = !uploadFile.url
  dialogVisible.value = true
}
const handleAvatarSuccess = response => {
  uploadLoading.value = false
  const d = {
    media_id: '',
    media_type: response.file.file_type === 'video' ? 'video' : 'photo',
    media_url: response.file.file_url,
    media_img: response.file.file_path,
    media_video: response.file.file_video_url
      ? response.file.file_video_url
      : '',
    img: response.file.file_path.img,
    square: response.file.file_path.square,
    thumb: response.file.file_path.thumb
  }
  postForm.goods_img.push(d)
}

const handleProgress = event => {
  if (event.percent > 1 && event.percent < 100) {
    uploadLoading.value = true
    uploadPercent.value = `${Math.round(event.percent)}%`
  }
}
const uploadGoodsImg = ref(null);
const uploadGoodsShow = ref(false);

const checkValidation = () => {
  // getBase64(val.raw)
  // let formItems = Object.keys(postForm)
  // for (let i = 0; i < formItems.length; i++) {
  //   if (postForm[formItems[i]] == '' || postForm[formItems[i]].length === 0) {
  //     return (formIsPass.value = false)
  //   }
  // }

  // return (formIsPass.value = true)
}

const isBtnEnabled = computed(() => {
  let formItems = Object.keys(postForm)
  for (let i = 0; i < formItems.length; i++) {
    if (postForm[formItems[i]] == '' || postForm[formItems[i]].length === 0) {
      return false
    }
  }

  return true
})

const getBase64 = (file) => {
  const reader = new FileReader();

  reader.readAsDataURL(file.raw)
  reader.onload = function () {
    uploadGoodsImg.value = reader.result
    uploadGoodsShow.value = true
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

const checkNumber = (inputModel, e) => {
  if (parseInt(e) < 0) {
    postForm[inputModel] = 0
  }
}

const onPreview = () => {
  dialogUrl.value = postForm.goods_img[0].media_url
  dialogType.value = postForm.goods_img[0].media_type

  dialogVisible.value = !dialogVisible.value
}

const onDelete = index => {
  postForm.goods_img.splice(index, 1)
  checkValidation()
}

const onAddPost = value => {
  let formData = {
    ...value
  }
  delete formData.publish_date
  formData.goods_img = value.goods_img[0].media_img

  // debugger
  addGoods(formData)
    .then(res => {
      // modals.value.hide()
      Object.assign(formData, {
        goods_name: '',
        goods_desc: '',
        goods_img: [],
        goods_original_price: '',
        goods_price: '',
        number: '',
        is_enabled: '1'
      })
      // store.dispatch('User/isPlanAction', false)
      $toast.show(res.message)
      setTimeout(() => {
        location.reload()
      }, 2000)
    })
    .catch(err => {
      $toast.show(err.response)
    })
}

const confirmCropper = () => {
  commom.value.btnLoader = true
  cropper.value.getCropData((data) => {
    const file = dataURLtoFile(data, 'a.png');
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", 'goods');
    upload(formData)
      .then((response) => {
        // $toast.show(res.message)
        commom.value.btnLoader = false
        uploadGoodsImg.value = ''
        uploadGoodsShow.value = false

        uploadLoading.value = false
        const d = {
          media_id: '',
          media_type: response.file.file_type === 'video' ? 'video' : 'photo',
          media_url: response.file.file_url,
          media_img: response.file.file_path,
          media_video: response.file.file_video_url
            ? response.file.file_video_url
            : '',
          img: response.file.file_path.img,
          square: response.file.file_path.square,
          thumb: response.file.file_path.thumb
        }
        postForm.goods_img.push(d)
      })
      .catch(err => {
        commom.value.btnLoader = false
        $toast.show(err.response.data.message)
      })
  });
}

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

onMounted(() => {
  if (isMobileDevice.value && window.innerWidth < 1023) {
    const screenWidth = window.innerWidth;
    const cropSize = Math.ceil(screenWidth * 0.9 - 20)
    option.value = {
      ...option.value,
      autoCropWidth: cropSize,
      autoCropHeight: cropSize
    }
  }
})
</script>

<style scoped>
.modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 2000;
}
.modal_inner {
  width: 90%;
  margin: 20px auto;
  background: #FFF;
  border-radius: 10px;
  padding: 10px;
}
.modal_inner img {
  max-width: 100%;
  max-height: 90vh;
}

.img_wrap {
  height: 200px;
}
</style>
